import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";
import Map from "./services/Map.js";
import Contact from "./services/Contact.js";

document.addEventListener(
	"DOMContentLoaded",
	() => {
		const overlay = new Overlay();
		const reviewsSlider = new Slider('[data-slider="reviews"]');
		const findUsMap = new Map('[data-map="find-us"]');
		const formContact = new Contact('[data-form="contact"]', [
			"name",
			"phone",
			"email",
			"message",
		]);
		const facilitySlider = new Slider('[data-slider="facility-images"]');

		// Toggle Mobile Menu
		(() => {
			const navOpenBtn = document.querySelector('[data-js="nav-open"]');
			const navCloseBtn = document.querySelector('[data-js="nav-close"]');
			const navMenu = document.querySelector('[data-js="nav-menu"]');

			if (!navMenu) {
				return;
			}

			if (navOpenBtn) {
				navOpenBtn.addEventListener("click", () => {
					navMenu.classList.add("active");
					overlay.show();
					overlay.addListenerOnClose(() => {
						navMenu.classList.remove("active");
					});
				});
			}

			if (navCloseBtn) {
				navCloseBtn.addEventListener("click", () => {
					navMenu.classList.remove("active");
					overlay.hide();
				});
			}
		})();

		// Single Facility - Filter Rates
		(() => {
			const facilityRatesWrapper = document.querySelector(
				".facility-rates-wrapper"
			);
			const facilityFilters = document.querySelector(".facility-filters");

			if (!facilityRatesWrapper || !facilityFilters) {
				return;
			}

			facilityFilters.addEventListener("change", () => {
				const formData = new FormData(facilityFilters);
				const filterName = formData.get("facility-filter");
				facilityRatesWrapper.setAttribute("data-filter", filterName);
			});
		})();

		//Single Facility mobile view toggle
		(() => {
			const facilityTitle = document.querySelector(".facility-mobile-title");
			const topElement = document.querySelector(".top");

			if (facilityTitle) {
				facilityTitle.addEventListener("click", () => {
					const chevron = facilityTitle.querySelector("#chevron");
					if (chevron.classList.contains("rotate-down")) {
						chevron.classList.remove("rotate-down");
						if (topElement.classList.contains("active")) {
							topElement.classList.remove("active");
						}
					} else {
						chevron.classList.add("rotate-down");
						if (topElement) {
							topElement.classList.add("active");
						}
					}
				});
			}
		})();
	},
	true
);
