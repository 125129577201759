import { contactSubmit } from "../util/contact.js";

export default class Contact {
	constructor(formSelector, requiredFileds) {
		this.formNode = document.querySelector(formSelector);
		this.requiredFileds = requiredFileds;
		this.init();
	}

	init() {
		if (!this.formNode) {
			return;
		}

		contactSubmit(this.formNode, this.requiredFileds);
	}
}
